import Alpine from 'alpinejs'
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import lozad from 'lozad';
import { Fancybox } from '@fancyapps/ui';

window.Alpine = Alpine;
Alpine.start();

const testimonialSwiper = new Swiper('.testimonialSwiper', {
  modules: [Navigation, Pagination, Autoplay],
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    // 640: {
    //   slidesPerView: 1,
    //   spaceBetween: 0,
    // },
    768: {
      slidesPerView: 2,
      spaceBetween: 5,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
  },
});

const simpleSwiper = new Swiper(".simpleSwiper", {
  modules: [Navigation, Pagination, Autoplay],
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

Fancybox.bind("[data-fancybox]", {});

// Initialize lozad
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();


const sizes = {
  icon: 100,
  thumb: 300,
  preview: 750,
  mls: 1800,
  print: 3360,
  original: 0,
  xxs: 100, 
  xs: 300,
  sm: 500,
  base: 750,
  lg: 1000,
  xl: 1500,
  xxl: 2500
};

const imageHandlerEndpoint = 'https://d2e08hhdrjhri.cloudfront.net';

const bgNodes = document.querySelectorAll('div[data-s3-key]');
for(var i = 0; i < bgNodes.length; i++) {
  bgNodes[i].setAttribute('data-background-image', urlFromNode(bgNodes[i]));
}

const nodes = document.querySelectorAll('img[data-s3-key]');
for(var i = 0; i < nodes.length; i++) {
  nodes[i].setAttribute('data-src', urlFromNode(nodes[i]));
}

const linkNodes = document.querySelectorAll('a[data-s3-key]');
for(var i = 0; i < linkNodes.length; i++) {
  linkNodes[i].setAttribute('href', urlFromNode(linkNodes[i]));
}

function urlFromNode(node) {
  return imageHandlerUrl({
    key: node.getAttribute('data-s3-key'),
    bucket: node.getAttribute('data-s3-bucket'),
    version: node.getAttribute('data-s3-version')
  }, { size: node.getAttribute('data-s3-size') });
}

function imageHandlerUrl(image, edits) {
  var cloudfareOptions = {
    bucket: image.bucket,
    key: image.key,
    versionId: image.version,
    edits: {}
  };
  if (edits) {
    injectSizeEdits(cloudfareOptions.edits, edits);
  }
  const request = JSON.stringify(cloudfareOptions);
  return `${imageHandlerEndpoint}/${btoa(request)}`;
}

function injectSizeEdits(cloudfareEdits, edits) {
  if (!edits) { edits = { size: 'base' }; }
  if (!edits.size) { edits.size = 'base'; }

  const width = (!sizes[edits.size]) ? sizes.base : sizes[edits.size];
  if (width === 0) { return; }

  cloudfareEdits.resize = { width, fit: 'contain' };
}